/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://drzp2fwh55cdll37ks32zxkq5y.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-3xdzaoppwbh3bksbrq4x5q7v4u",
    "aws_cognito_identity_pool_id": "us-east-2:c1d81f65-e7ab-4103-9629-e0659bf6439d",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_woIWqzjd1",
    "aws_user_pools_web_client_id": "5k06ne2l5shnao2ir9igllg63d",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "reactportfolioacef214b7dd0426eb2bbaa09c74b535e222321-staging",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
